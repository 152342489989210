<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-text>
        <v-container v-if="isPermission('location-amenities.create')">
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.amenity_id"
                dense
                :items="amenities"
                item-value="id"
                item-text="name"
                :label="$t('select_amenity_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-btn
                color="primary"
                small
                :loading="isLoading"
                @click="saveAmenity"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <location-amenities-list
          v-if="isPermission('location-amenities.list')"
          :row="item"
        />
      </v-card-text>
      <v-card-actions>
        <!-- <small>{{ $t("indicates_required_field") }}</small> -->
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  components: {
    LocationAmenitiesList: () => import("./LocationsAmenitiesList.vue"),
  },
  mixins: [validationRules],
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isLoading: false,
      options: {},
      //
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      amenities: "csmsLocations/getAmenities",
      //
    }),
  },

  async mounted() {
    await this.$store.dispatch("csmsLocations/amenities");
  },
  methods: {
    isPermission(val) {
      return this.$admin.can(val);
    },
    async saveAmenity() {
      if (this.$refs.form.validate()) {
        this.options.location_id = this.item?.id;
        this.isLoading = true;
        await this.$store
          .dispatch("csmsLocations/addAmenities", this.options)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.$refs.form.reset();
            // this.fetchLocationImageList();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
          })
          .finally(() => (this.isLoading = false));
        //     this.$emit("onsave", this.options);
      }
    },
    closeForm() {
      this.$refs.form.reset();
      this.$emit("onclose");
    },

    // when Img Add
    // fetchLocationImageList() {
    //   const params = {
    //     page: 1,
    //     itemsPerPage: 20,
    //     location_id: this.item?.id,
    //   };
    //   this.$store.dispatch("csmsLocations/locationImgList", params);
    // },
  },
};
</script>
